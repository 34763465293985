const topSwiper = new Swiper('.top-slider', {
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  speed: 2000,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  loop: true
});
